<template>
  <TsCarouselV2
    ref="mainCarouselRef"
    v-if="props.tabs.length > 0"
    :pagination="false"
    :navigation="!isMobile"
    :drag-free="true"
    :append-class="twMerge('px-0 -m-1', props.appendClass)"
    viewport-class="pb-2"
    prev-button-class="-mt-1"
    next-button-class="-mt-1"
  >
    <template v-for="(tab, index) in props.tabs">
      <TsCarouselSlide v-if="tab" :key="tab.id" :append-class="twMerge('flex-[0_0_auto] p-1 lg:p-1', props.bordered && 'p-0 lg:p-0')">
        <TsButton
          :label="tab.label ?? tab"
          :link="tab.link && typeof tab.link === 'string' ? tab.link : ''"
          :icon="tab.icon"
          :append-class="
            twMerge(
              'px-3 focus:ring-0 text-nowrap border-[#E9E9E9] max-sm:shadow-[0px_4px_12px_0px_#E3E3E399] cursor-pointer',
              props.active !== tab.id &&
                'bg-idle-white text-natural-grey hover:text-white font-semibold',
              props.noShadow && 'max-sm:shadow-none shadow-none',
              props.bordered && `border-0 rounded-none bg-transparent text-gray-500 border-b-2 hover:bg-transparent hover:text-gray-500 ${props.active === tab.id && 'border-b-primary text-primary hover:text-primary'}`,
            )
          "
          rounded
          :data-testid="`${props.dataTestid}-${tab.id}-tab`"
          @click="setActiveTag(tab, index)"
          size="sm"
        />
      </TsCarouselSlide>
    </template>
  </TsCarouselV2>
</template>

<script setup lang="ts">
import { twMerge } from "tailwind-merge";
import { ref } from "vue";
import { scrollTo } from "../../utils/carouselUtils";

const { isMobile } = useDevice();
const mainCarouselRef = ref(null);
const mainCarouselApi = ref(null);
const selected_index = ref(0);

type Tag = {
  id?: string;
  label: string;
  link?: string;
  icon?: string;
};

type Props = {
  tabs?: Tag[] | string[];
  active?: number | string;
  align?: "left" | "center" | "right";
  dataTestid?: string;
  noShadow?: boolean;
  bordered?: boolean;
  appendClass?: string;
};

const emit = defineEmits(["tabClick"]);

const props = withDefaults(defineProps<Props>(), {
  tabs: () => [
    { id: "1", label: "All" },
    { id: "2", label: "All - 2" },
    { id: "3", label: "All - 3" },
  ],
  align: "left",
  active: "1",
});

const setActiveTag = (tab: typeof props.tabs[0], index: number) => {
  selected_index.value = index;
  emit("tabClick", tab, index);
};

onMounted(() => {
  nextTick(() => {
    if (mainCarouselRef.value) {
      mainCarouselApi.value = mainCarouselRef.value?.emblaApi;

      if (mainCarouselApi.value && props.tabs && props.tabs.length > 0) {
        // Ensure that props.tabs has elements and safely access them
        const activeIndex = props.tabs.findIndex((item) => item?.id === props.active);

        // Set the selected index only if a valid index is found
        if (activeIndex !== -1) {
          selected_index.value = activeIndex;
          scrollTo(mainCarouselApi, selected_index.value);
        }
      }
    }
  });
});
</script>
